import React, { ReactElement } from 'react'
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom'
import { BasketProvider } from './context/BasketProvider'
import Personalise from './pages/personalise/Personalise'
import { RoutePath } from './types/routes'
import Home from './pages/Home'
import { PurchaserDataProvider } from './context/PurchaserProvider'
import { OrganisationProvider } from './context/OrganisationProvider'
import { ManualOrderProvider } from './context/ManualOrderProvider'
import { ErrorProvider } from './context/ErrorProvider'
import Router from './components/router/Router'
import Confirmation from './pages/Confirmation'
import Details from './pages/details/Details'
import Payment from './pages/payment/Payment'

function App(): ReactElement {
  return (
    <BrowserRouter>
      <ErrorProvider>
        <ManualOrderProvider>
          <OrganisationProvider>
            <PurchaserDataProvider>
              <BasketProvider>
                <Router>
                  <Switch>
                    <Route
                      path={RoutePath.Personalise}
                      component={Personalise}
                    />
                    <Route path={RoutePath.Basket} component={Details} />
                    <Route path={RoutePath.Payment} component={Payment} />
                    <Route
                      path={RoutePath.Confirmation}
                      component={Confirmation}
                    />
                    <Route path={`${RoutePath.Home}:id`} component={Home} />
                    <Route path={RoutePath.Home} component={Home} />
                  </Switch>
                </Router>
              </BasketProvider>
            </PurchaserDataProvider>
          </OrganisationProvider>
        </ManualOrderProvider>
      </ErrorProvider>
    </BrowserRouter>
  )
}

export default App
